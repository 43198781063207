<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Заполнить GUID</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="white pa-5 rounded-0">
        <v-row>
          <v-col cols="4">
            <label>
              Идентификатор
              <v-text-field
                v-model="guid"
                dense
                outlined
                hide-details="true"
              />
            </label>
          </v-col>
        </v-row>
      </div>
      <div>
        <div class="d-flex justify-end">
          <v-btn
            dark
            color="#5CB7B1"
            class="mx-5 mt-5"
            :disabled="!guid"
            @click="submit"
          >
            Подтвердить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";
import { mapGetters } from 'vuex';
export default {
  mixins:[modalWindowMixin],
  props:{
    id:{
      type:Number,
      required:true,
    },
    module: {
      type:String,
      required:true,
    }
  },
  data:() => ({
    guid:null,
  }),
  computed:{
    ...mapGetters({
      user:"auth/getUser"
    })
  },

  methods:{
    unset(){
      this.guid = null
    },
    submit(){
      if(this.id <= 0) return false;
      this.$store.dispatch(`${this.module}/fillGuid`, {id:this.id, guid:this.guid}).then(() => {
        this.$snackbar({text:"GUID изменен",color:"green", top:false, right: false});
        this.$store.dispatch('journal/fetchItems')
        this.unset()
        this.close()
      }).catch((err) => {
        const text = err.response.status === 403 ? "Недостаточно прав": "Ошибка"
        this.$snackbar({text,color:"red", top:false, right: false});
      })
    }
  }


}
</script>
